<template>
  <label
    @click="toggle"
    @keypress.space="toggle"
    :aria-label="`Check ${$slots.default[0].text}`"
    :aria-checked="selected"
    :class="['BaseFlag fs-14', { 'BaseFlag--active': selected }]"
    role="checkbox"
    tabindex="0"
  >
    <slot />
  </label>
</template>

<script>

export default {
  name: 'BaseFlag',

  props: {
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data () {
    return {
      selected: this.active,
    }
  },

  watch: {
    active () {
      this.selected = this.active
    },
  },

  methods: {
    toggle () {
      this.selected = !this.selected
      this.$emit('toggle', {
        text: this.$slots.default[0].text,
        toggled: this.selected
      })
    },
  },
}
</script>

<style lang="sass">
.BaseFlag
  background-color: #FBFBFD
  border: $border
  border-radius: $border-radius
  cursor: pointer
  color: #3E3F42
  outline: none
  padding: rem(4px) rem(11px)
  text-transform: capitalize
  transition: all 0.15s cubic-bezier(.25, .46, .45, .94)
  user-select: none

  &:focus
    border-color: $danger

  &--active
    background-color: $danger
    border-color: darken($danger, 10%)
    box-shadow: inset 0 rem(.5px) rem(0px) rem(0px) lighten($danger, 10), $shadow-b
    color: $white

</style>

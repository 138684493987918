var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      class: ["BaseFlag fs-14", { "BaseFlag--active": _vm.selected }],
      attrs: {
        "aria-label": "Check " + _vm.$slots.default[0].text,
        "aria-checked": _vm.selected,
        role: "checkbox",
        tabindex: "0"
      },
      on: {
        click: _vm.toggle,
        keypress: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
          ) {
            return null
          }
          return _vm.toggle($event)
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
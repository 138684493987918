<template>
  <div class="Styleguide">
    <!-- COLORS -->
    <section class="Styleguide__section-wrapper">
      <h1 class="Styleguide__section-header fs-20 fw-med">Colors</h1>
      <hr class="Styleguide__section-divider">

      <div class="Styleguide__content-wrapper">
        <div
          v-for="(color, index) in colors"
          :key="index"
          class="Styleguide__color-wrapper"
        >
          <div
            class="Styleguide__color-example"
            :style="`background-color: #${color.hex}`"
          />
          <label class="fs-14 fw-med">{{ color.name }}</label>
          <label class="fc-light fs-14">{{ color.hex }}</label>
        </div>
      </div>
    </section>

    <!-- TYPOGRAPHY -->
    <section class="Styleguide__section-wrapper">
      <h1 class="Styleguide__section-header fs-20 fw-med">
        Typography
      </h1>
      <hr class="Styleguide__section-divider">

      <div class="Styleguide__content-wrapper">
        <div class="column">
          <div class="Styleguide__typography-row-wrapper row">
            <div class="Styleguide__typography-main-lbl-wrapper">
              <label class="fc-light fs-12 fw-med uppercase">Font</label>
              <label class="fs-60 fw-med">Roboto</label>
            </div>
            <div class="Styleguide__typography-main-lbl-wrapper">
              <label class="fc-light fs-12 fw-med uppercase">Font Weights</label>
              <div class="row">
                <label class="fs-36 fw-med">Medium</label>
                <label class="fs-36">Regular</label>
              </div>
            </div>
          </div>

          <div class="Styleguide__typography-row-wrapper column">
            <label class="Styleguide__row-lbl--32 fc-light fs-12 fw-med uppercase">Pre-defined Sizes</label>
            <div class="row row--align-end row--justify-between row--wrap">
              <div
                v-for="(size, index) in fontSizes"
                :key="index"
                class="Styleguide__typography-size-wrapper column"
              >
                <label :class="`fw-med fs-${size}`">
                  Aa
                </label>
                <hr class="Styleguide__section-divider">
                <label class="fc-light fs-14 uppercase">{{ size }}px</label>
              </div>
            </div>
          </div>

          <div class="Styleguide__typography-row-wrapper column">
            <label class="Styleguide__row-lbl--32 fc-light fs-12 fw-med uppercase">Examples</label>
            <div class="row">
              <div class="Styleguide__typography-example column">
                <h1 class="fs-26 fw-med">
                  Citrus Lentil Salad
                </h1>
                <p class="fc-light fs-14">
                  “Rinse the lentils under cold running water in a fine-mesh sieve until the water runs clear.
                  Place the lentils in a medium saucepan and add enough cold water to cover by 3 inches
                  (7.6 centimeters). Bring to a boil, then reduce the heat to medium-low, cover,
                  and simmer for 20 to 30 minutes or until the lentils are tender.”
                </p>
              </div>
              <div class="Styleguide__typography-example column">
                <h1 class="fs-30 fw-med">
                  Barbecued Shrimp
                </h1>
                <p class="fs-16">
                  “Rinse the shrimp and pat them dry with paper towels.
                  Arrange them in a single layer in a 13-by-9-inch (33-by-23-centimeter)
                  baking dish.
                </p>
                <label class="fc-light fs-12">
                  Excerpt From: Williams, Nathan.
                  “Kinfolk Table : Recipes for Small Gatherings (9781579655877).” iBooks.
                </label>
              </div>
              <div class="Styleguide__typography-example column">
                <label class="fc-light fs-12 fw-med uppercase">For the Hash</label>
                <h1 class="fs-18 fw-med">
                  “Sweet Potato Hash with Sausage and Egg”
                </h1>
                <p class="fs-16">
                  “This dish is a complete meal. But if you are looking for something a little lighter,
                  the hash and poached egg can stand on their own without the sausage,
                  and that way it’s vegetarian, too.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- BUTTONS -->
    <section class="Styleguide__section-wrapper">
      <h1 class="Styleguide__section-header fs-20 fw-med">
        Buttons
      </h1>
      <hr class="Styleguide__section-divider">

      <div class="Styleguide__content-wrapper">
        <div class="row">
          <div class="column">
            <div class="column">
              <label class="Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase">Main Buttons</label>
              <div class="row">
                <base-button
                  v-for="(button, index) in buttons.mainButtons"
                  :key="index"
                  @click="helloWorld"
                  :class="`Styleguide__button-example ${button.class}`"
                >
                  <template>
                    <i
                      v-if="button.icon"
                      :class="`fa fa-${button.icon}`"
                    />
                    {{ button.text }}
                  </template>
                </base-button>
              </div>
            </div>
            <div class="column">
              <label class="Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase">
                Main Buttons (Hover)
              </label>
              <div class="row">
                <base-button
                  v-for="(button, index) in buttons.mainButtons"
                  :key="index"
                  @click="helloWorld"
                  :class="
                    `Styleguide__button-example Styleguide__button-example--hover ${button.class}`
                  "
                >
                  <template>
                    <i
                      v-if="button.icon"
                      :class="`fa fa-${button.icon}`"
                    />
                    {{ button.text }}
                  </template>
                </base-button>
              </div>
            </div>
            <div class="column">
              <label class="Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase">
                Main Buttons (Focus)
              </label>
              <div class="row">
                <base-button
                  v-for="(button, index) in buttons.mainButtons"
                  :key="index"
                  @click="helloWorld"
                  :class="
                    `Styleguide__button-example Styleguide__button-example--focus ${button.class}`
                  "
                >
                  <template>
                    <i
                      v-if="button.icon"
                      :class="`fa fa-${button.icon}`"
                    />
                    {{ button.text }}
                  </template>
                </base-button>
              </div>
            </div>
            <div class="column">
              <label class="Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase">
                Main Buttons (Active)
              </label>
              <div class="row">
                <base-button
                  v-for="(button, index) in buttons.mainButtons"
                  :key="index"
                  @click="helloWorld"
                  :class="
                    `Styleguide__button-example Styleguide__button-example--active ${button.class}`
                  "
                >
                  <template>
                    <i
                      v-if="button.icon"
                      :class="`fa fa-${button.icon}`"
                    />
                    {{ button.text }}
                  </template>
                </base-button>
              </div>
            </div>
            <div class="column">
              <label class="Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase">
                Main Buttons (Disabled)
              </label>
              <div class="row">
                <base-button
                  v-for="(button, index) in buttons.mainButtons"
                  :key="index"
                  @click="helloWorld"
                  :class="`Styleguide__button-example ${button.class}`"
                  :disabled="true"
                >
                  <template>
                    <i
                      v-if="button.icon"
                      :class="`fa fa-${button.icon}`"
                    />
                    {{ button.text }}
                  </template>
                </base-button>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="column">
              <label class="Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase">Smaller Buttons</label>
              <div class="row">
                <base-button
                  v-for="(button, index) in buttons.smallerButtons"
                  :key="index"
                  @click="helloWorld"
                  :class="`Styleguide__button-example ${button.class}`"
                >
                  {{ button.text }}
                </base-button>
              </div>
            </div>
            <div class="column">
              <label class="Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase">
                Smaller Buttons (Hover)
              </label>
              <div class="row">
                <base-button
                  v-for="(button, index) in buttons.smallerButtons"
                  :key="index"
                  @click="helloWorld"
                  :class="
                    `Styleguide__button-example Styleguide__button-example--hover ${button.class}`
                  "
                >
                  {{ button.text }}
                </base-button>
              </div>
            </div>
            <div class="column">
              <label class="Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase">
                Smaller Buttons (Focus)
              </label>
              <div class="row">
                <base-button
                  v-for="(button, index) in buttons.smallerButtons"
                  :key="index"
                  @click="helloWorld"
                  :class="
                    `Styleguide__button-example Styleguide__button-example--focus ${button.class}`
                  "
                >
                  {{ button.text }}
                </base-button>
              </div>
            </div>
            <div class="column">
              <label class="Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase">
                Smaller Buttons (Active)
              </label>
              <div class="row">
                <base-button
                  v-for="(button, index) in buttons.smallerButtons"
                  :key="index"
                  @click="helloWorld"
                  :class="
                    `Styleguide__button-example Styleguide__button-example--active ${button.class}`
                  "
                >
                  {{ button.text }}
                </base-button>
              </div>
            </div>
            <div class="column">
              <label class="Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase">
                Smaller Buttons (Disabled)
              </label>
              <div class="row">
                <base-button
                  v-for="(button, index) in buttons.smallerButtons"
                  :key="index"
                  @click="helloWorld"
                  :class="`Styleguide__button-example ${button.class}`"
                  :disabled="true"
                >
                  {{ button.text }}
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- INPUTS -->
    <section class="Styleguide__section-wrapper">
      <h1 class="Styleguide__section-header fs-20 fw-med">
        Inputs
      </h1>
      <hr class="Styleguide__section-divider">

      <div class="Styleguide__content-wrapper">
        <div class="column">
          <div class="row">
            <div class="row row--wrap">
              <base-input
                v-for="(input, index) in inputs"
                :key="index"
                :class="input.class"
                :instructions="input.instructions"
                :label="true"
                :label-for="'ExampleID'"
                :placeholder="'Placeholder'"
                :type="input.type ? input.type : 'text'"
                :valid="input.valid"
                :value="input.value"
              >
                LABEL
              </base-input>
            </div>
            <base-textarea
              :label="true"
              :label-for="'ExampleID'"
              :placeholder="'Ryan Reynolds just fired back at Kanye West for being insane'"
            >
              Textarea
            </base-textarea>
          </div>
          <div class="Styleguide__bg-row row row--align-center">
            <base-date-picker />
            <base-date-picker :valid="false" />
            <base-toggle
              :emits-default-on-load="false"
              :toggles="['valid', 'error']"
            />
            <base-flag>Flag</base-flag>
          </div>
        </div>
      </div>
    </section>

    <!-- SELECTS AND SEARCH INPUTS -->
    <section class="Styleguide__section-wrapper">
      <h1 class="Styleguide__section-header fs-20 fw-med">
        Selects and Search Inputs
      </h1>
      <hr class="Styleguide__section-divider">

      <div class="Styleguide__content-wrapper">
        <div class="row row--align-end">
          <v-select
            class="Styleguide__input-example"
            :clearable="false"
            :options="options"
            :placeholder="'Select an update reason'"
          />
          <base-client-search
            class="Styleguide__input-example"
            :label="true"
            :label-for="'ExampleForID'"
          >
            <template v-slot:label>
              Client
            </template>
          </base-client-search>
          <base-debtor-search
            class="Styleguide__input-example"
            :label="true"
            :label-for="'ExampleForID'"
          >
            <template v-slot:label>
              Debtor
            </template>
          </base-debtor-search>
        </div>
      </div>
    </section>

    <!-- ICONS -->
    <section class="Styleguide__section-wrapper">
      <h1 class="Styleguide__section-header fs-20 fw-med">
        Icons
      </h1>
      <hr class="Styleguide__section-divider">
    </section>

    <!-- TABLES -->
    <section class="Styleguide__section-wrapper">
      <h1 class="Styleguide__section-header fs-20 fw-med">
        Tables
      </h1>
      <hr class="Styleguide__section-divider">
    </section>

    <!-- NAVIGATION -->
    <section class="Styleguide__section-wrapper">
      <h1 class="Styleguide__section-header fs-20 fw-med">
        Navigation
      </h1>
      <hr class="Styleguide__section-divider">
    </section>

    <!-- TOP FILTERS -->
    <section class="Styleguide__section-wrapper">
      <h1 class="Styleguide__section-header fs-20 fw-med">
        Top Filters
      </h1>
      <hr class="Styleguide__section-divider">
    </section>

    <!-- PAGINATION -->
    <section class="Styleguide__section-wrapper">
      <h1 class="Styleguide__section-header fs-20 fw-med">
        Pagination
      </h1>
      <hr class="Styleguide__section-divider">
    </section>

    <!-- MISC -->
    <section class="Styleguide__section-wrapper">
      <h1 class="Styleguide__section-header fs-20 fw-med">
        Miscellaneous
      </h1>
      <hr class="Styleguide__section-divider">

      <div class="Styleguide__content-wrapper">
        <label class="fs-18 mb-12">Debtor Ratings</label>

        <div class="column">
          <label class="fc-light fs-12 uppercase mb-12">Small</label>
          <div class="row">
            <base-debtor-rating
              v-for="(rating, index) in ratings"
              :key="`small-${index}`"
              class="mr-10 mb-12"
              :rating="rating"
              :size="'small'"
            />
          </div>
        </div>
        <div class="column">
          <label class="fc-light fs-12 uppercase mb-12">Medium</label>
          <div class="row">
            <base-debtor-rating
              v-for="(rating, index) in ratings"
              :key="`medium-${index}`"
              class="mr-10 mb-12"
              :rating="rating"
              :size="'medium'"
            />
          </div>
        </div>
        <div class="column">
          <label class="fc-light fs-12 uppercase mb-12">Large</label>
          <div class="row">
            <base-debtor-rating
              v-for="(rating, index) in ratings"
              :key="`large-${index}`"
              class="mr-10 mb-12"
              :rating="rating"
              :size="'large'"
            />
          </div>
        </div>
        <div class="column">
          <label class="fc-light fs-12 uppercase mb-12">Enormous</label>
          <div class="row">
            <base-debtor-rating
              v-for="(rating, index) in ratings"
              :key="`enormous-${index}`"
              class="mr-10 mb-12"
              :rating="rating"
              :size="'enormous'"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// Components
import BaseButton from '../components/base-button.vue'
import BaseClientSearch from '../components/base-client-search.vue'
import BaseDebtorRating from '../components/base-debtor-rating.vue'
import BaseDebtorSearch from '../components/base-debtor-search.vue'
import BaseDatePicker from '../components/base-date-picker.vue'
import BaseFlag from '../components/base-flag.vue'
import BaseInput from '../components/base-input.vue'
import BaseTextarea from '../components/base-textarea.vue'
import BaseToggle from '../components/base-toggle.vue'

export default {
  name: 'ApprovedInvoices',

  components: {
    BaseButton,
    BaseClientSearch,
    BaseDebtorRating,
    BaseDebtorSearch,
    BaseDatePicker,
    BaseFlag,
    BaseInput,
    BaseTextarea,
    BaseToggle,
  },

  created () {
    window.addEventListener('keyup', this.logActiveElement)
  },

  beforeDestroy () {
    window.removeEventListener('keyup', this.logActiveElement)
  },

  data () {
    return {
      buttons: {
        mainButtons: [
          {
            class: 'bg-green fc-white',
            icon: 'add-circle',
            text: 'New Invoice',
          },
          {
            class: 'bg-blue fc-white',
            icon: '',
            text: 'Submit',
          },
          {
            class: 'bg-green fc-white',
            icon: '',
            text: 'Upload Document',
          },
          {
            class: 'bg-white fc-blue',
            icon: 'search-left',
            text: 'Search',
          },
          {
            class: 'bg-white fc-blue',
            icon: 'optionbars',
            text: 'Filter',
          },
        ],
        smallerButtons: [
          {
            class: 'bg-blue fc-white',
            text: 'Submit',
          },
          {
            class: 'bg-blue fc-white',
            text: 'Yes',
          },
          {
            class: 'bg-blue fc-white',
            text: 'Send',
          },
        ],
      },
      colors: [
        {
          hex: '2845A4',
          name: 'Highlight Color - Blue',
        },
        {
          hex: '33AC12',
          name: 'Green',
        },
        {
          hex: 'FFC000',
          name: 'Warning Yellow',
        },
        {
          hex: 'E6492D',
          name: 'Red',
        },
        {
          hex: '434447',
          name: 'Darker Text Color',
        },
        {
          hex: '6B6C6F',
          name: 'Middle Text Color',
        },
        {
          hex: '9EA0A5',
          name: 'Smaller Text + Icon Color',
        },
        {
          hex: 'AFB1B5',
          name: 'Table Headers',
        },
        {
          hex: 'C3C4C7',
          name: 'Select Color',
        },
        {
          hex: '#D8DCE6',
          name: 'Border Color',
        },
        {
          hex: 'FFFFFF',
          name: 'Background Color',
        },
      ],
      fontSizes: ['60', '36', '30', '26', '22', '20', '18', '16', '14', '13', '12', '11'],
      inputs: [
        {
          class: 'Styleguide__input-example',
          instructions: null,
          valid: true,
          value: '',
        },
        {
          class: 'Styleguide__input-example',
          instructions: null,
          valid: true,
          value: 'Value',
        },
        {
          class: 'Styleguide__input-example',
          instructions: null,
          type: 'password',
          valid: true,
          value: 'password',
        },
        {
          class: 'Styleguide__input-example Styleguide__input-example--focus',
          instructions: null,
          valid: true,
          value: 'Focus',
        },
        {
          class: 'Styleguide__input-example',
          instructions: 'Letters and spaces only',
          valid: false,
          value: 'Error',
        },
      ],
      options: [
        'debtor claim',
        'paid to carrier',
        'paperwork unclear',
        'missing paperwork',
        'originals needed',
        'client balance',
        'unreadable',
        'missing rate con',
        'missing BOL',
        'rate con/BOL mismatch',
        'missing signature',
        'rate mismatch',
        'credit rating',
        'possible claim',
        'wrong debtor',
        'no delivery options'
      ],
      ratings: ['A', 'B', 'C', 'D', 'F'],
    }
  },

  computed: {
  },

  methods: {
    logActiveElement () {
      this.CLog(document.activeElement)
    },

    flagged (response) {
      this.toggleValidity(response.toggled)
    },

    helloWorld () {
      this.CLog('hello world')
    },

    toggleValidity (validity = null) {
      if (!validity) {
        this.valid = !this.valid
        return
      }
      if (validity === 'error') this.valid = false
      if (validity === 'valid') this.valid = true
    }
  },
}
</script>

<style lang="sass">
@import '../styles/base/styleguide.sass'
</style>

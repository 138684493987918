var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Styleguide" }, [
    _c("section", { staticClass: "Styleguide__section-wrapper" }, [
      _c("h1", { staticClass: "Styleguide__section-header fs-20 fw-med" }, [
        _vm._v("Colors")
      ]),
      _c("hr", { staticClass: "Styleguide__section-divider" }),
      _c(
        "div",
        { staticClass: "Styleguide__content-wrapper" },
        _vm._l(_vm.colors, function(color, index) {
          return _c(
            "div",
            { key: index, staticClass: "Styleguide__color-wrapper" },
            [
              _c("div", {
                staticClass: "Styleguide__color-example",
                style: "background-color: #" + color.hex
              }),
              _c("label", { staticClass: "fs-14 fw-med" }, [
                _vm._v(_vm._s(color.name))
              ]),
              _c("label", { staticClass: "fc-light fs-14" }, [
                _vm._v(_vm._s(color.hex))
              ])
            ]
          )
        }),
        0
      )
    ]),
    _c("section", { staticClass: "Styleguide__section-wrapper" }, [
      _c("h1", { staticClass: "Styleguide__section-header fs-20 fw-med" }, [
        _vm._v("\n      Typography\n    ")
      ]),
      _c("hr", { staticClass: "Styleguide__section-divider" }),
      _c("div", { staticClass: "Styleguide__content-wrapper" }, [
        _c("div", { staticClass: "column" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "Styleguide__typography-row-wrapper column" },
            [
              _c(
                "label",
                {
                  staticClass:
                    "Styleguide__row-lbl--32 fc-light fs-12 fw-med uppercase"
                },
                [_vm._v("Pre-defined Sizes")]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "row row--align-end row--justify-between row--wrap"
                },
                _vm._l(_vm.fontSizes, function(size, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "Styleguide__typography-size-wrapper column"
                    },
                    [
                      _c("label", { class: "fw-med fs-" + size }, [
                        _vm._v("\n                Aa\n              ")
                      ]),
                      _c("hr", { staticClass: "Styleguide__section-divider" }),
                      _c("label", { staticClass: "fc-light fs-14 uppercase" }, [
                        _vm._v(_vm._s(size) + "px")
                      ])
                    ]
                  )
                }),
                0
              )
            ]
          ),
          _vm._m(1)
        ])
      ])
    ]),
    _c("section", { staticClass: "Styleguide__section-wrapper" }, [
      _c("h1", { staticClass: "Styleguide__section-header fs-20 fw-med" }, [
        _vm._v("\n      Buttons\n    ")
      ]),
      _c("hr", { staticClass: "Styleguide__section-divider" }),
      _c("div", { staticClass: "Styleguide__content-wrapper" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "column" }, [
            _c("div", { staticClass: "column" }, [
              _c(
                "label",
                {
                  staticClass:
                    "Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase"
                },
                [_vm._v("Main Buttons")]
              ),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.buttons.mainButtons, function(button, index) {
                  return _c(
                    "base-button",
                    {
                      key: index,
                      class: "Styleguide__button-example " + button.class,
                      on: { click: _vm.helloWorld }
                    },
                    [
                      [
                        button.icon
                          ? _c("i", { class: "fa fa-" + button.icon })
                          : _vm._e(),
                        _vm._v(
                          "\n                  " +
                            _vm._s(button.text) +
                            "\n                "
                        )
                      ]
                    ],
                    2
                  )
                }),
                1
              )
            ]),
            _c("div", { staticClass: "column" }, [
              _c(
                "label",
                {
                  staticClass:
                    "Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase"
                },
                [_vm._v("\n              Main Buttons (Hover)\n            ")]
              ),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.buttons.mainButtons, function(button, index) {
                  return _c(
                    "base-button",
                    {
                      key: index,
                      class:
                        "Styleguide__button-example Styleguide__button-example--hover " +
                        button.class,
                      on: { click: _vm.helloWorld }
                    },
                    [
                      [
                        button.icon
                          ? _c("i", { class: "fa fa-" + button.icon })
                          : _vm._e(),
                        _vm._v(
                          "\n                  " +
                            _vm._s(button.text) +
                            "\n                "
                        )
                      ]
                    ],
                    2
                  )
                }),
                1
              )
            ]),
            _c("div", { staticClass: "column" }, [
              _c(
                "label",
                {
                  staticClass:
                    "Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase"
                },
                [_vm._v("\n              Main Buttons (Focus)\n            ")]
              ),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.buttons.mainButtons, function(button, index) {
                  return _c(
                    "base-button",
                    {
                      key: index,
                      class:
                        "Styleguide__button-example Styleguide__button-example--focus " +
                        button.class,
                      on: { click: _vm.helloWorld }
                    },
                    [
                      [
                        button.icon
                          ? _c("i", { class: "fa fa-" + button.icon })
                          : _vm._e(),
                        _vm._v(
                          "\n                  " +
                            _vm._s(button.text) +
                            "\n                "
                        )
                      ]
                    ],
                    2
                  )
                }),
                1
              )
            ]),
            _c("div", { staticClass: "column" }, [
              _c(
                "label",
                {
                  staticClass:
                    "Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase"
                },
                [_vm._v("\n              Main Buttons (Active)\n            ")]
              ),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.buttons.mainButtons, function(button, index) {
                  return _c(
                    "base-button",
                    {
                      key: index,
                      class:
                        "Styleguide__button-example Styleguide__button-example--active " +
                        button.class,
                      on: { click: _vm.helloWorld }
                    },
                    [
                      [
                        button.icon
                          ? _c("i", { class: "fa fa-" + button.icon })
                          : _vm._e(),
                        _vm._v(
                          "\n                  " +
                            _vm._s(button.text) +
                            "\n                "
                        )
                      ]
                    ],
                    2
                  )
                }),
                1
              )
            ]),
            _c("div", { staticClass: "column" }, [
              _c(
                "label",
                {
                  staticClass:
                    "Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase"
                },
                [
                  _vm._v(
                    "\n              Main Buttons (Disabled)\n            "
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.buttons.mainButtons, function(button, index) {
                  return _c(
                    "base-button",
                    {
                      key: index,
                      class: "Styleguide__button-example " + button.class,
                      attrs: { disabled: true },
                      on: { click: _vm.helloWorld }
                    },
                    [
                      [
                        button.icon
                          ? _c("i", { class: "fa fa-" + button.icon })
                          : _vm._e(),
                        _vm._v(
                          "\n                  " +
                            _vm._s(button.text) +
                            "\n                "
                        )
                      ]
                    ],
                    2
                  )
                }),
                1
              )
            ])
          ]),
          _c("div", { staticClass: "column" }, [
            _c("div", { staticClass: "column" }, [
              _c(
                "label",
                {
                  staticClass:
                    "Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase"
                },
                [_vm._v("Smaller Buttons")]
              ),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.buttons.smallerButtons, function(button, index) {
                  return _c(
                    "base-button",
                    {
                      key: index,
                      class: "Styleguide__button-example " + button.class,
                      on: { click: _vm.helloWorld }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(button.text) +
                          "\n              "
                      )
                    ]
                  )
                }),
                1
              )
            ]),
            _c("div", { staticClass: "column" }, [
              _c(
                "label",
                {
                  staticClass:
                    "Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase"
                },
                [
                  _vm._v(
                    "\n              Smaller Buttons (Hover)\n            "
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.buttons.smallerButtons, function(button, index) {
                  return _c(
                    "base-button",
                    {
                      key: index,
                      class:
                        "Styleguide__button-example Styleguide__button-example--hover " +
                        button.class,
                      on: { click: _vm.helloWorld }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(button.text) +
                          "\n              "
                      )
                    ]
                  )
                }),
                1
              )
            ]),
            _c("div", { staticClass: "column" }, [
              _c(
                "label",
                {
                  staticClass:
                    "Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase"
                },
                [
                  _vm._v(
                    "\n              Smaller Buttons (Focus)\n            "
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.buttons.smallerButtons, function(button, index) {
                  return _c(
                    "base-button",
                    {
                      key: index,
                      class:
                        "Styleguide__button-example Styleguide__button-example--focus " +
                        button.class,
                      on: { click: _vm.helloWorld }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(button.text) +
                          "\n              "
                      )
                    ]
                  )
                }),
                1
              )
            ]),
            _c("div", { staticClass: "column" }, [
              _c(
                "label",
                {
                  staticClass:
                    "Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase"
                },
                [
                  _vm._v(
                    "\n              Smaller Buttons (Active)\n            "
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.buttons.smallerButtons, function(button, index) {
                  return _c(
                    "base-button",
                    {
                      key: index,
                      class:
                        "Styleguide__button-example Styleguide__button-example--active " +
                        button.class,
                      on: { click: _vm.helloWorld }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(button.text) +
                          "\n              "
                      )
                    ]
                  )
                }),
                1
              )
            ]),
            _c("div", { staticClass: "column" }, [
              _c(
                "label",
                {
                  staticClass:
                    "Styleguide__row-lbl--16 fc-light fs-12 fw-med uppercase"
                },
                [
                  _vm._v(
                    "\n              Smaller Buttons (Disabled)\n            "
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.buttons.smallerButtons, function(button, index) {
                  return _c(
                    "base-button",
                    {
                      key: index,
                      class: "Styleguide__button-example " + button.class,
                      attrs: { disabled: true },
                      on: { click: _vm.helloWorld }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(button.text) +
                          "\n              "
                      )
                    ]
                  )
                }),
                1
              )
            ])
          ])
        ])
      ])
    ]),
    _c("section", { staticClass: "Styleguide__section-wrapper" }, [
      _c("h1", { staticClass: "Styleguide__section-header fs-20 fw-med" }, [
        _vm._v("\n      Inputs\n    ")
      ]),
      _c("hr", { staticClass: "Styleguide__section-divider" }),
      _c("div", { staticClass: "Styleguide__content-wrapper" }, [
        _c("div", { staticClass: "column" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "div",
                { staticClass: "row row--wrap" },
                _vm._l(_vm.inputs, function(input, index) {
                  return _c(
                    "base-input",
                    {
                      key: index,
                      class: input.class,
                      attrs: {
                        instructions: input.instructions,
                        label: true,
                        "label-for": "ExampleID",
                        placeholder: "Placeholder",
                        type: input.type ? input.type : "text",
                        valid: input.valid,
                        value: input.value
                      }
                    },
                    [_vm._v("\n              LABEL\n            ")]
                  )
                }),
                1
              ),
              _c(
                "base-textarea",
                {
                  attrs: {
                    label: true,
                    "label-for": "ExampleID",
                    placeholder:
                      "Ryan Reynolds just fired back at Kanye West for being insane"
                  }
                },
                [_vm._v("\n            Textarea\n          ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "Styleguide__bg-row row row--align-center" },
            [
              _c("base-date-picker"),
              _c("base-date-picker", { attrs: { valid: false } }),
              _c("base-toggle", {
                attrs: {
                  "emits-default-on-load": false,
                  toggles: ["valid", "error"]
                }
              }),
              _c("base-flag", [_vm._v("Flag")])
            ],
            1
          )
        ])
      ])
    ]),
    _c("section", { staticClass: "Styleguide__section-wrapper" }, [
      _c("h1", { staticClass: "Styleguide__section-header fs-20 fw-med" }, [
        _vm._v("\n      Selects and Search Inputs\n    ")
      ]),
      _c("hr", { staticClass: "Styleguide__section-divider" }),
      _c("div", { staticClass: "Styleguide__content-wrapper" }, [
        _c(
          "div",
          { staticClass: "row row--align-end" },
          [
            _c("v-select", {
              staticClass: "Styleguide__input-example",
              attrs: {
                clearable: false,
                options: _vm.options,
                placeholder: "Select an update reason"
              }
            }),
            _c("base-client-search", {
              staticClass: "Styleguide__input-example",
              attrs: { label: true, "label-for": "ExampleForID" },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function() {
                    return [_vm._v("\n            Client\n          ")]
                  },
                  proxy: true
                }
              ])
            }),
            _c("base-debtor-search", {
              staticClass: "Styleguide__input-example",
              attrs: { label: true, "label-for": "ExampleForID" },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function() {
                    return [_vm._v("\n            Debtor\n          ")]
                  },
                  proxy: true
                }
              ])
            })
          ],
          1
        )
      ])
    ]),
    _vm._m(2),
    _vm._m(3),
    _vm._m(4),
    _vm._m(5),
    _vm._m(6),
    _c("section", { staticClass: "Styleguide__section-wrapper" }, [
      _c("h1", { staticClass: "Styleguide__section-header fs-20 fw-med" }, [
        _vm._v("\n      Miscellaneous\n    ")
      ]),
      _c("hr", { staticClass: "Styleguide__section-divider" }),
      _c("div", { staticClass: "Styleguide__content-wrapper" }, [
        _c("label", { staticClass: "fs-18 mb-12" }, [_vm._v("Debtor Ratings")]),
        _c("div", { staticClass: "column" }, [
          _c("label", { staticClass: "fc-light fs-12 uppercase mb-12" }, [
            _vm._v("Small")
          ]),
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.ratings, function(rating, index) {
              return _c("base-debtor-rating", {
                key: "small-" + index,
                staticClass: "mr-10 mb-12",
                attrs: { rating: rating, size: "small" }
              })
            }),
            1
          )
        ]),
        _c("div", { staticClass: "column" }, [
          _c("label", { staticClass: "fc-light fs-12 uppercase mb-12" }, [
            _vm._v("Medium")
          ]),
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.ratings, function(rating, index) {
              return _c("base-debtor-rating", {
                key: "medium-" + index,
                staticClass: "mr-10 mb-12",
                attrs: { rating: rating, size: "medium" }
              })
            }),
            1
          )
        ]),
        _c("div", { staticClass: "column" }, [
          _c("label", { staticClass: "fc-light fs-12 uppercase mb-12" }, [
            _vm._v("Large")
          ]),
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.ratings, function(rating, index) {
              return _c("base-debtor-rating", {
                key: "large-" + index,
                staticClass: "mr-10 mb-12",
                attrs: { rating: rating, size: "large" }
              })
            }),
            1
          )
        ]),
        _c("div", { staticClass: "column" }, [
          _c("label", { staticClass: "fc-light fs-12 uppercase mb-12" }, [
            _vm._v("Enormous")
          ]),
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.ratings, function(rating, index) {
              return _c("base-debtor-rating", {
                key: "enormous-" + index,
                staticClass: "mr-10 mb-12",
                attrs: { rating: rating, size: "enormous" }
              })
            }),
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "Styleguide__typography-row-wrapper row" },
      [
        _c("div", { staticClass: "Styleguide__typography-main-lbl-wrapper" }, [
          _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
            _vm._v("Font")
          ]),
          _c("label", { staticClass: "fs-60 fw-med" }, [_vm._v("Roboto")])
        ]),
        _c("div", { staticClass: "Styleguide__typography-main-lbl-wrapper" }, [
          _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
            _vm._v("Font Weights")
          ]),
          _c("div", { staticClass: "row" }, [
            _c("label", { staticClass: "fs-36 fw-med" }, [_vm._v("Medium")]),
            _c("label", { staticClass: "fs-36" }, [_vm._v("Regular")])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "Styleguide__typography-row-wrapper column" },
      [
        _c(
          "label",
          {
            staticClass:
              "Styleguide__row-lbl--32 fc-light fs-12 fw-med uppercase"
          },
          [_vm._v("Examples")]
        ),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "Styleguide__typography-example column" }, [
            _c("h1", { staticClass: "fs-26 fw-med" }, [
              _vm._v("\n                Citrus Lentil Salad\n              ")
            ]),
            _c("p", { staticClass: "fc-light fs-14" }, [
              _vm._v(
                "\n                “Rinse the lentils under cold running water in a fine-mesh sieve until the water runs clear.\n                Place the lentils in a medium saucepan and add enough cold water to cover by 3 inches\n                (7.6 centimeters). Bring to a boil, then reduce the heat to medium-low, cover,\n                and simmer for 20 to 30 minutes or until the lentils are tender.”\n              "
              )
            ])
          ]),
          _c("div", { staticClass: "Styleguide__typography-example column" }, [
            _c("h1", { staticClass: "fs-30 fw-med" }, [
              _vm._v("\n                Barbecued Shrimp\n              ")
            ]),
            _c("p", { staticClass: "fs-16" }, [
              _vm._v(
                "\n                “Rinse the shrimp and pat them dry with paper towels.\n                Arrange them in a single layer in a 13-by-9-inch (33-by-23-centimeter)\n                baking dish.\n              "
              )
            ]),
            _c("label", { staticClass: "fc-light fs-12" }, [
              _vm._v(
                "\n                Excerpt From: Williams, Nathan.\n                “Kinfolk Table : Recipes for Small Gatherings (9781579655877).” iBooks.\n              "
              )
            ])
          ]),
          _c("div", { staticClass: "Styleguide__typography-example column" }, [
            _c("label", { staticClass: "fc-light fs-12 fw-med uppercase" }, [
              _vm._v("For the Hash")
            ]),
            _c("h1", { staticClass: "fs-18 fw-med" }, [
              _vm._v(
                "\n                “Sweet Potato Hash with Sausage and Egg”\n              "
              )
            ]),
            _c("p", { staticClass: "fs-16" }, [
              _vm._v(
                "\n                “This dish is a complete meal. But if you are looking for something a little lighter,\n                the hash and poached egg can stand on their own without the sausage,\n                and that way it’s vegetarian, too.\n              "
              )
            ])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "Styleguide__section-wrapper" }, [
      _c("h1", { staticClass: "Styleguide__section-header fs-20 fw-med" }, [
        _vm._v("\n      Icons\n    ")
      ]),
      _c("hr", { staticClass: "Styleguide__section-divider" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "Styleguide__section-wrapper" }, [
      _c("h1", { staticClass: "Styleguide__section-header fs-20 fw-med" }, [
        _vm._v("\n      Tables\n    ")
      ]),
      _c("hr", { staticClass: "Styleguide__section-divider" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "Styleguide__section-wrapper" }, [
      _c("h1", { staticClass: "Styleguide__section-header fs-20 fw-med" }, [
        _vm._v("\n      Navigation\n    ")
      ]),
      _c("hr", { staticClass: "Styleguide__section-divider" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "Styleguide__section-wrapper" }, [
      _c("h1", { staticClass: "Styleguide__section-header fs-20 fw-med" }, [
        _vm._v("\n      Top Filters\n    ")
      ]),
      _c("hr", { staticClass: "Styleguide__section-divider" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "Styleguide__section-wrapper" }, [
      _c("h1", { staticClass: "Styleguide__section-header fs-20 fw-med" }, [
        _vm._v("\n      Pagination\n    ")
      ]),
      _c("hr", { staticClass: "Styleguide__section-divider" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }